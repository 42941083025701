import React, {useEffect, useState} from "react";
import "./suggestion.css";
import axios from "axios";

const Suggestion = ({
  search,
  setsearch,
  suggestions,
  setSuggestions,
  data,
  setData,
  setFilterSearch,
  setFilterCategory,
  setFilterBrands,
  setFilterMinMaxPrice,
  dispatch,
  searchNavTrigger,
  navigate,
  curr_url,
}) => {
  //   console.log("dddd", data);
  const filteredSuggestions = (data || []).filter((item) => {
    // Log the item for debugging
    console.log("Item:", item);

    // Ensure item is defined and is a string
    return (
      typeof item === "string" &&
      item.toLowerCase().includes(search.toLowerCase())
    );
  });
  const clickHandler = (item) => {
    setsearch(item);
    setSuggestions(false);
    if (search !== "") {
      dispatch(setFilterSearch({data: search}));
      dispatch(setFilterCategory({data: null}));
      dispatch(setFilterBrands({data: []}));
      dispatch(setFilterMinMaxPrice({data: null}));
      searchNavTrigger.current.click();
      navigate("/products");
      if (curr_url.pathname !== "/products") {
      }
    }
  };
  return (
    <div className="suggestion_main">
      {filteredSuggestions.slice(0, 6).map((item, index) => (
        <div
          key={index}
          className="suggestion_child"
          onClick={() => clickHandler(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Suggestion;
